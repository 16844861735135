@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: "Poppins", sans-serif;
	background-color: #030306;
	/* max-height: 100vh; */
	/* overflow-y: hidden; */
}
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}
.active {
	left: 0;
}
.linear {
	background: linear-gradient(
		90.1deg,
		rgba(73, 167, 245, 0.15) 0.07%,
		rgba(196, 196, 196, 0) 99.91%
	);
}

*{
	scroll-behavior: smooth;
}
